import gsap, { Power4 } from "gsap";

export const splitLines = (e: HTMLElement, t: string, o: string) => {
  let n = 0,
    r = "",
    a = !0;
  (e.innerHTML = e.innerHTML.replace(/(<font[^>]+>|<font>|<\/font>)/g, "")),
    (e.innerHTML = e.innerHTML.replace(/\S+/g, "<n>$&</n>"));
  for (let c = e.children, i = 0; i < c.length; i++) {
    let l = c[i].getBoundingClientRect().top;
    n < l && (a ? ((r += t), (a = !1)) : (r += o + t)), (n = l), (r += c[i].textContent + " ");
  }
  e.innerHTML = r += o;
};

export const animationScrollPage = () => {
  let spanIntro: NodeListOf<HTMLElement> = document.querySelectorAll(".to-split.intro-reveal");
  spanIntro.forEach((e: HTMLElement) => rafTextRevealIntro(e));
  setupObserver(".reveal-block", revealBlockAnimation);
  setupObserver(".to-split.scroll-reveal", rafTextReveal);
  setupObserver(".to-animate", animateItems);
  setupObserver(".to-animateSVG", animateSVGs);
};

const setupObserver = (selector: string, callback: Function) => {
  const elements = document.querySelectorAll(selector);
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback(entry.target as HTMLElement);
          observer.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      rootMargin: "0px 0px -25% 0px",
      threshold: 0,
    }
  );

  elements.forEach((el) => observer.observe(el));
};

const revealBlockAnimation = (element: HTMLElement) => {
  setTimeout(
    () => {
      element.classList.add("animate");
    },
    element.classList.value.includes("intro-reveal") ? 800 : 0
  );
};

const rafTextReveal = (element: HTMLElement) => {
  gsap.to(element.querySelectorAll(".line-container span"), {
    y: "0%",
    ease: Power4.easeOut,
    stagger: 0.08,
    duration: 1,
  });
  element.classList.remove("scroll-reveal");
};

const rafTextRevealIntro = (element: HTMLElement) => {
  gsap.to(element.querySelectorAll(".line-container span"), {
    y: "0%",
    ease: Power4.easeOut,
    stagger: 0.08,
    duration: 1,
    delay: 0.8,
  });
  element.classList.remove("scroll-reveal");
};

const animateItems = (item: HTMLElement) => {
  setTimeout(
    () => {
      item.classList.add("animate");
    },
    item.classList.value.includes("intro-reveal") ? 800 : 0
  );
};

const animateSVGs = (svg: HTMLElement) => {
  gsap.to(svg.querySelectorAll("svg"), {
    y: "0%",
    opacity: 1,
    ease: Power4.easeOut,
    stagger: 0.08,
    duration: 1,
  });
};
