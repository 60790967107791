import TypeDataDevis from "./types/data-form-devis";

export const validateName = (name: string): boolean => {
  const regex = /^[a-zA-Z\s]+$/;
  return regex.test(name);
};

export const validateEmail = (email: string): boolean => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const validatePhone = (phone: string): boolean => {
  const regex = /^[0-9\s\-\(\)]+$/;
  return regex.test(phone);
};

export const validateCompany = (company: string): boolean => {
  const regex = /^[a-zA-Z0-9\s]+$/;
  return regex.test(company);
};

export const validateComment = (comment: string): boolean => {
  const regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
  return !regex.test(comment);
};

export const sanitizeInput = (input: string): string => {
  return input.replace(/['";<>{}]/g, "");
};

export const validateFormData = (data: TypeDataDevis): string[] => {
  const errors: string[] = [];

  if (!data.startDate) errors.push("La date de début est requise.");
  if (!data.endDate) errors.push("La date de fin est requise.");
  if ((data.deliveryOption === "true" || data.deliveryOption ) && data.deliveryOption !== 'false') {
    if (!data.depositTime) errors.push("Le temps de dépôt est requis lorsque l'option de livraison est activée.");
    if (!data.pickupTime) errors.push("Le temps de récupération est requis lorsque l'option de livraison est activée.");
    if (!data.siteAddress) errors.push("L'adresse du site est requise lorsque l'option de livraison est activée.");
    if (!data.postalCode) errors.push("Le code postal est requis lorsque l'option de livraison est activée.");
    if (!data.city) errors.push("La ville est requise lorsque l'option de livraison est activée.");
  }
  if (!data.name) errors.push("Le nom est requis.");
  if (!data.email) errors.push("L'email est requis.");
  if (!data.phone) errors.push("Le numéro de téléphone est requis.");
  if (!data.contactPreference) errors.push("La préférence de contact est requise.");
  if (!data.acceptPolicy) errors.push("L'acceptation de la politique est requise.");



  return errors;
};
