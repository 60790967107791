import Cookies from "js-cookie";
import { createContext, FC, ReactNode, useEffect, useState } from "react";
import TypeCompleteProduct from "../types/product-complete";

interface VarContextProps {
  DevisContent: TypeCompleteProduct[];
  setDevisContent: (name: TypeCompleteProduct[]) => void;
}

const VarContext = createContext<VarContextProps | undefined>(undefined);

const VarProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // Charger les données du cookie ou initialiser avec un tableau vide
  const [DevisContent, setDevisContent] = useState<TypeCompleteProduct[]>(() => {
    const cookieData = Cookies.get('devisContent');
    return cookieData ? JSON.parse(cookieData) : [];
  });

  // Sauvegarder le contenu du panier dans un cookie à chaque modification
  useEffect(() => {
    Cookies.set('devisContent', JSON.stringify(DevisContent), { expires: 7 });
  }, [DevisContent]);

  return (
    <VarContext.Provider value={{ DevisContent, setDevisContent }}>
      {children}
    </VarContext.Provider>
  );
};

export { VarContext, VarProvider };

