const fetchGetPageFields = async (pageId: number) => {
    var data: any = ''
    try {
        const response = await fetch(`/wp-json/v1/get-page-fields/${pageId}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
  
        });
        data =   {data : await response.json(), status: response.status};
    } catch {
        return false
    }
    // console.log(data);
    return data
  }
  export default fetchGetPageFields;