const fetchGetListCategory = async () => {
  var data: any = ''
  try {
      const response = await fetch("/wp-json/v1/product-categories", {
          method: "GET",
          headers: {
              'Content-Type': 'application/json',
          },

      });
      data =  {data : await response.json(), status: response.status};
  } catch {
      return false
  }
//   console.log(data);
  return data
}
export default fetchGetListCategory;