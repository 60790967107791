import { AnimatePresence, motion } from "framer-motion";
import { FC, useEffect, useState } from "react";
import { animationScrollPage } from "../utils/animations";
import { useFirstLoad } from "../utils/var-context/load-context";
import Logo from "./main-logo";

const transition = (OgComponent: FC<any>): FC<any> => {
  const TransitionWrapper: FC<any> = (props) => {
    const [isVisible, setIsVisible] = useState(true);
    const { isFirstLoad, setFirstLoad } = useFirstLoad(); // Utilisez le contexte

    useEffect(() => {
      if (isFirstLoad) {
        // Mettre à jour l'état global après la première animation
        setFirstLoad(false);
      }
    }, [isFirstLoad, setFirstLoad]);

    useEffect(() => {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
      const timer = setTimeout(() => {
        setIsVisible(false);

        animationScrollPage();
      }, 750); // 1 seconde pour l'animation

      return () => clearTimeout(timer);
    }, []);

    return (
      <>
        <AnimatePresence>
          {isVisible && (
            <motion.div
              className="slide-in"
              initial={isFirstLoad ? { scaleY: 1 } : { scaleY: 0 }}
              animate={{ scaleY: 1 }}
              exit={{ scaleY: 0 }}
              transition={{ duration: 0.5, ease: [0.22, 1, 0.36, 1] }}
            >
              <p className="logotest">
                <Logo />
              </p>
            </motion.div>
          )}
        </AnimatePresence>
        <OgComponent {...props} />
      </>
    );
  };

  return TransitionWrapper;
};

export default transition;
