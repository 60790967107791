import { FC, useEffect, useState } from "react";
import transition from "../../components/transition";
import { animationScrollPage } from "../../utils/animations";
import { isValidReq } from "../../utils/check-status";
import fetchGetPageFields from "../../utils/request/wp-get-page-fields";
import "./faq.css";
import Ariane from "../../components/ariane";
import MetaTags from "../../components/metatags";

interface FaqElement {
  title: string;
  para: string;
}

interface FaqData {
  titre_faq: string;
  element_faq_1: FaqElement;
  element_faq_2: FaqElement;
  element_faq_3: FaqElement;
  titre_conseil: string;
  page_1: { post_name: number; post_title: string }[];
  page_2: { post_name: number; post_title: string }[];
  page_3: { post_name: number; post_title: string }[];
  metatitle: string;
  metadescription: string;
}

const FAQ: FC = () => {
  const [data, setData] = useState<FaqData>();
  useEffect(() => {
    animationScrollPage();
  }, [data]);

  const wpPageID: number = 81;
  useEffect(() => {
    fetchGetPageFields(wpPageID).then((result) => {
      if (isValidReq(result.status)) {
        setData(result.data);
      }
    });
  }, []);
  const [activeFAQ, setActiveFAQ] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setActiveFAQ(activeFAQ === index ? null : index);
  };

  return data ? (
    <article className="page scroll-container" data-router-view="custom" id="page-faq">
      <MetaTags  title={data.metatitle} description={data.metadescription} image=''  name='' />
      <div data-scroll>
        <Ariane name="FAQ" />
        <section className="page__container">
          <div className="text-intro">
            <h1 className="barlowBold">{data.titre_faq}</h1>

            {Object.entries(data)
              .filter(([key]) => key.startsWith("element_faq"))
              .map(([key, faq], index) => {
                const faqItem = faq as FaqElement;
                if (faqItem.title === "" || faqItem.para === "") return;
                return (
                  <div key={key} className="question_block dark_grey">
                    <h2 className="barlowBold black" onClick={() => toggleFAQ(index)} style={{ cursor: "pointer" }}>
                      {faqItem.title}
                    </h2>
                    {activeFAQ === index && <div className="response open" dangerouslySetInnerHTML={{ __html: faqItem.para }} />}
                  </div>
                );
              })}



            <h1 className="barlowBold">{data.titre_conseil}</h1>
            <div className="question_block nos_conseils dark_grey">
              {data.page_1.map((page) => (
                <h2 className="barlowBold black" key={page.post_name}>
                  <a href={`/${page.post_name}`}>{page.post_title.replace("[Nos conseils] ", "")}</a>
                </h2>
              ))}
              {data.page_2.map((page) => (
                <h2 className="barlowBold black" key={page.post_name}>
                  <a href={`/${page.post_name}`}>{page.post_title.replace("[Nos conseils] ", "")}</a>
                </h2>
              ))}
              {data.page_3.map((page) => (
                <h2 className="barlowBold black" key={page.post_name}>
                  <a href={`/${page.post_name}`}>{page.post_title.replace("[Nos conseils] ", "")}</a>
                </h2>
              ))}
            </div>
          </div>
        </section>
      </div>
    </article>
  ) : null;
};

export default transition(FAQ);
