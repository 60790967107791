const fetchCities = async (postalCode: string) => {
    try {
      const response = await fetch('https://unpkg.com/codes-postaux@3.3.0/codes-postaux.json', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const cities = await response.json();
  
      return cities.filter((city: any) => city.codePostal.startsWith(postalCode));
    } catch (error) {
      return false;
    }
  };
  
export default fetchCities;