import { FC } from "react";

const Logo: FC = () => {
  return (
    <svg id="logo" xmlns="http://www.w3.org/2000/svg" width="210" height="54.397" viewBox="0 0 210 54.397">
      <path
        id="Tracé_165"
        data-name="Tracé 165"
        d="M316.587,299.9v-.209C316.583,299.825,316.587,299.9,316.587,299.9Z"
        transform="translate(-302.048 -271.155)"
        fill="#fff"
      ></path>
      <path
        id="Tracé_166"
        data-name="Tracé 166"
        d="M504.294,305.315a5.028,5.028,0,0,1-5.021,5.014l-199.957-.263a5.044,5.044,0,0,1-5.022-5.028V260.947a5.029,5.029,0,0,1,5.022-5.015l199.957.263a5.044,5.044,0,0,1,5.021,5.028Z"
        transform="translate(-294.294 -255.932)"
        fill="#ffd400"
      ></path>
      <path
        id="Tracé_167"
        data-name="Tracé 167"
        d="M567.12,304.863a2.635,2.635,0,0,1-2.622,2.627l-68.124.11a2.625,2.625,0,0,1-2.623-2.618V267.763a2.635,2.635,0,0,1,2.623-2.627l68.124-.11a2.625,2.625,0,0,1,2.622,2.618Z"
        transform="translate(-363.673 -259.095)"
        fill="#161615"
      ></path>
      <path
        id="Tracé_168"
        data-name="Tracé 168"
        d="M400.976,299.73c-.592,1.651-1.184,2.773-3.645,2.773-2.493,0-3.085-1.122-3.677-2.773L389.2,287.205c-.125-.342-.28-.747-.467-.747-.312,0-.312.53-.312.685l.156,15.111h-5.609V283.56c0-2.929,2.773-3.365,4.424-3.365a4.483,4.483,0,0,1,4.736,3.209l4.58,12.587c.125.374.374.9.623.9s.5-.53.623-.9l4.58-12.587a4.471,4.471,0,0,1,4.7-3.209c3.584,0,4.456,1.683,4.456,3.365v18.694h-5.609l.187-15.111c0-.436-.156-.685-.342-.685-.156,0-.312.312-.468.747Z"
        transform="translate(-325.138 -264.372)"
        fill="#161615"
      ></path>
      <path
        id="Tracé_169"
        data-name="Tracé 169"
        d="M437.948,282.75c.717-1.714,1.62-2.555,4.3-2.555,1.963,0,3.4.436,4.268,2.555l8.163,19.5h-5.888l-1.963-4.985h-9.191l-1.963,4.985h-5.858Zm1.06,10.281h6.449l-2.773-6.917c-.125-.342-.218-.685-.436-.685s-.342.343-.467.685Z"
        transform="translate(-341.434 -264.372)"
        fill="#161615"
      ></path>
      <path
        id="Tracé_170"
        data-name="Tracé 170"
        d="M497.04,302.319c-2.026,0-3.3-1-3.3-3.334v-18.6h5.609v16.112c0,1.09.374,1.465,1.465,1.465h11.216v4.362Z"
        transform="translate(-363.668 -264.436)"
        fill="#fff"
      ></path>
      <path
        id="Tracé_171"
        data-name="Tracé 171"
        d="M545.1,291.349c0,9.1-3.022,11.154-11.59,11.154s-11.59-2.056-11.59-11.154c0-9.067,3.022-11.154,11.59-11.154S545.1,282.283,545.1,291.349Zm-11.59,6.792c4.549,0,5.858-1.651,5.858-6.792s-1.309-6.792-5.858-6.792-5.858,1.651-5.858,6.792S528.965,298.142,533.514,298.142Z"
        transform="translate(-373.473 -264.372)"
        fill="#fff"
      ></path>
      <path
        id="Tracé_172"
        data-name="Tracé 172"
        d="M570.186,302.387c-7.322,0-9.378-4.051-9.378-10.9s2.056-10.905,9.378-10.905h9v4.362h-6.231c-4.985,0-6.419,1.246-6.419,6.543s1.433,6.543,6.419,6.543H579.5v4.362Z"
        transform="translate(-386.998 -264.505)"
        fill="#fff"
      ></path>
      <path
        id="Tracé_173"
        data-name="Tracé 173"
        d="M334.611,291.482c0-6.387-1.621-10.905-8.911-10.905h-8.755c-2.025,0-3.3,1.028-3.3,3.365v15.111c0,2.337,1.278,3.334,3.3,3.334h9.036c7.01,0,8.631-4.517,8.631-10.9ZM323.534,298a3.9,3.9,0,0,0-4.28,3.921h0V286.5c0-1.184.374-1.558,1.557-1.558h1.278a18.939,18.939,0,0,1,2.041.1h0a6.607,6.607,0,0,1,2.573.727c.025.014.052.028.076.043a3.735,3.735,0,0,1,.312.211c.035.025.068.052.1.079a3.441,3.441,0,0,1,.264.233c.034.033.066.069.1.1.08.085.157.171.229.264.026.035.049.073.074.109a4.062,4.062,0,0,1,.4.711c.008.016.016.031.023.048.041.095.076.2.112.3s.064.172.091.263.056.2.083.307.053.215.077.329.04.209.059.318q.033.2.059.4c.014.1.027.209.038.318.016.164.029.337.04.513.006.1.014.187.018.286.012.28.02.569.02.876h0c0,4.494-1.818,6.46-5.071,6.516Z"
        transform="translate(-301.024 -264.505)"
      ></path>
      <path
        id="Tracé_174"
        data-name="Tracé 174"
        d="M359.167,296.031l9.347-15.454h-5.982l-6.137,10.438-6.138-10.438h-5.982l9.316,15.454v1.18h0v5.182h2.274c2.026,0,3.3-1,3.3-3.335v-2.544h0Z"
        transform="translate(-311.679 -264.505)"
      ></path>
      <path
        id="Tracé_175"
        data-name="Tracé 175"
        d="M474.137,280.38h-10.2v0a2.289,2.289,0,0,0-2.53,2.564v1.794h7.124v14.119c0,2.338,1.278,3.335,3.3,3.335h2.307V288.657c-.085-1.112-.669-3.914-4.416-3.914H482.49V280.38Z"
        transform="translate(-352.421 -264.436)"
      ></path>
    </svg>
  );
};

export default Logo