import { FC, useContext, useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Ariane from "../../components/ariane";
import SVG from "../../components/svg";
import transition from "../../components/transition";
import { animationScrollPage, splitLines } from "../../utils/animations";
import { isValidReq } from "../../utils/check-status";
import {
  sanitizeInput,
  validateCompany,
  validateEmail,
  validateFormData,
  validateName,
  validatePhone,
} from "../../utils/field-verification";
import fetchCities from "../../utils/request/get-villes";
import fetchPostFormDevis from "../../utils/request/wp-post-form-devis";
import TypeListCategory from "../../utils/types/category-list";
import TypeDataDevis from "../../utils/types/data-form-devis";
import TypeCompleteProduct from "../../utils/types/product-complete";
import { VarContext } from "../../utils/var-context/devis-context";
import MetaTags from "../../components/metatags";

import "./devis.css";

const PageDevis: FC<{ listCategorys: TypeListCategory[] }> = ({ listCategorys }) => {
  const context = useContext(VarContext);
  if (!context) return null;
  const { DevisContent, setDevisContent } = context;

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });

    animationScrollPage();
  }, []);
  const navigate = useNavigate();

  const [attributesOpen, setAttributesOpen] = useState<number | false>(false);
  const [AttrOpenedNotSelected, setAttrOpenedNotSelected] = useState<number[]>([]);
  const [FormStep, setFormStep] = useState<number>(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  useEffect(() => {
    const isAllValid = DevisContent.every((product) => {
      if (product.attributes.length === 0) {
        return true;
      }
      return product.attributes.some((attr) => attr.selected === true);
    });
    setIsButtonDisabled(isAllValid);
  }, [DevisContent]);

  useLayoutEffect(() => {
    if (DevisContent) {
      Array.prototype.forEach.call(document.querySelectorAll(".to-split span"), function (e, index) {
        splitLines(e, '<span class="line-container"><span>', "</span></span>");
      });
      animationScrollPage();
    }
  }, [DevisContent]);

  const [formData, setFormData] = useState<TypeDataDevis>({
    startDate: "",
    endDate: "",
    deliveryOption: "false",
    depositTime: "",
    pickupTime: "",
    siteAddress: "",
    postalCode: "",
    city: "",
    civility: "",
    companyName: "",
    name: "",
    email: "",
    phone: "",
    contactPreference: "",
    acceptPolicy: false,
    listItems: DevisContent,
  });

  const validateFormForStep = (formData: TypeDataDevis, formStep: number): boolean => {
    if (formStep === 1) {
      if (!formData.startDate || !formData.endDate) {
        return false;
      }

      if (formData.deliveryOption === "true") {
        if (!formData.depositTime || !formData.pickupTime || !formData.siteAddress || !formData.postalCode || !formData.city) {
          return false;
        }
      }
    }

    if (formStep === 2) {
      if (
        !formData.name ||
        !formData.email ||
        !formData.phone ||
        !validateEmail(formData.email) ||
        !validateName(formData.name) ||
        !validatePhone(formData.phone) ||
        !formData.contactPreference ||
        !formData.acceptPolicy
      ) {
        return false;
      }
    }

    return true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    const checked = type === "checkbox" ? (e.target as HTMLInputElement).checked : undefined;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === "checkbox" ? checked : sanitizeInput(value),
      };
      setIsButtonDisabled(validateFormForStep(updatedData, FormStep));
      return updatedData;
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      alert("Veuillez entrer une adresse email valide.");
      return;
    }

    if (!validateName(formData.name)) {
      alert("Veuillez entrer un nom valide.");
      return;
    }

    if (!validatePhone(formData.phone)) {
      alert("Veuillez entrer un numero de telephone valide.");
      return;
    }

    if (!validateCompany(formData.companyName)) {
      alert("Veuillez entrer un nom de société valide.");
      return;
    }

    const validationErrors = validateDevisForm(formData, FormStep);

    if (validationErrors.length > 0) {
      alert(validationErrors.join("\n"));
      return;
    }

    const requiredDataError: string[] = validateFormData(formData);
    if (requiredDataError.length !== 0) {
      alert(requiredDataError.join("\n"));
      return;
    }
    let startDate = new Date(formData.startDate);
    let startDay = "";
    if(startDate.getDate() < 10){
      startDay = "0" + startDate.getDate();
    }else{
      startDay = "" + startDate.getDate();
    }
    let startMonth = "";
    if((startDate.getMonth()+1) < 10){
      startMonth = "0" + (startDate.getMonth()+1);
    }else{
      startMonth = "" + (startDate.getMonth()+1);
    }
    formData.startDate = startDay + "/" + startMonth + "/" + startDate.getFullYear();
    let endDate = new Date(formData.endDate);
    let endDay = "";
    if(endDate.getDate() < 10){
      endDay = "0" + startDate.getDate();
    }else{
      endDay = "" + startDate.getDate();
    }
    let endMonth = "";
    if((endDate.getMonth()+1) < 10){
      endMonth = "0" + (endDate.getMonth()+1);
    }else{
      endMonth = "" + (endDate.getMonth()+1);
    }
    formData.endDate = endDay + "/" + endMonth + "/" + endDate.getFullYear();

    fetchPostFormDevis(formData).then((result) => {
      if (isValidReq(result.status)) {
        setDevisContent([]);
        setFormStep(3);
        //navigate("/");
      } else console.log(result);
    });
  };

  const validateDevisForm = (formData: TypeDataDevis, formStep: number): string[] => {
    const errors: string[] = [];

    // Validation pour le FormStep 1
    if (formStep === 1) {
      if (!formData.startDate) {
        errors.push("La date de début est obligatoire.");
      }

      if (!formData.endDate) {
        errors.push("La date de fin est obligatoire.");
      }

      if (formData.deliveryOption === "true") {
        if (!formData.depositTime) {
          errors.push("L'heure de dépôt est obligatoire lorsque la livraison est choisie.");
        }

        if (!formData.pickupTime) {
          errors.push("L'heure de retrait est obligatoire lorsque la livraison est choisie.");
        }

        if (!formData.siteAddress) {
          errors.push("L'adresse du chantier est obligatoire lorsque la livraison est choisie.");
        }

        if (!formData.postalCode) {
          errors.push("Le code postal est obligatoire lorsque la livraison est choisie.");
        }

        if (!formData.city) {
          errors.push("La ville est obligatoire lorsque la livraison est choisie.");
        }
      }

      if (!formData.acceptPolicy) {
        errors.push("Vous devez accepter la politique de confidentialité.");
      }
    }

    // Validation pour le FormStep 2
    if (formStep === 2) {
      if (!formData.name) {
        errors.push("Le nom est obligatoire.");
      }

      if (!formData.email) {
        errors.push("L'email est obligatoire.");
      }

      if (!formData.phone) {
        errors.push("Le téléphone est obligatoire.");
      }

      if (!formData.contactPreference) {
        errors.push("Vous devez sélectionner un moyen de contact (email ou téléphone).");
      }
    }

    return errors;
  };

  const handleSelectOption = (indexOption: number, indexProduct: number, isSelected: boolean) => {
    const prevContent: TypeCompleteProduct[] = [...DevisContent];
    //prevContent[indexProduct].options.forEach((e) => (e.value = false));
    if (!isSelected) {
      prevContent[indexProduct].options[indexOption].value = true;
    } else {
      prevContent[indexProduct].options[indexOption].value = false;
    }
    setDevisContent(prevContent);
  };

  const handleSelectAttribute = (indexAttribute: number, indexProduct: number, isSelected: boolean) => {
    const prevContent: TypeCompleteProduct[] = [...DevisContent];
    prevContent[indexProduct].attributes.forEach((e) => (e.selected = false));
    if (!isSelected) {
      prevContent[indexProduct].attributes[indexAttribute].selected = true;
      setAttrOpenedNotSelected(AttrOpenedNotSelected.filter((item) => item !== indexProduct));
      setAttributesOpen(false);
    }
    setDevisContent(prevContent);
  };

  const DeleteItemDevis = (index: number) => {
    const updatedItemsArray = [...DevisContent];
    updatedItemsArray.splice(index, 1);
    setDevisContent(updatedItemsArray);
  };

  const toggleAttributesContainer = (indexProduct: number) => {
    if (indexProduct === attributesOpen) {
      setAttributesOpen(false);
      let tempoListError = [...AttrOpenedNotSelected];
      if (DevisContent[indexProduct].attributes.find((e) => e.selected == true)) {
        tempoListError = tempoListError.filter((item) => item !== indexProduct);
      } else {
        if (!tempoListError.includes(indexProduct)) tempoListError.push(indexProduct);
      }
      setAttrOpenedNotSelected(tempoListError);
    } else if (!attributesOpen) {
      setAttributesOpen(indexProduct);
    }
  };

  const [cities, setCities] = useState<any[]>([]); // Stocke les villes retournées par le fetch

  useEffect(() => {
    const cp = formData.postalCode;
    if (cp.length === 5) {
      fetchCities(cp).then((result) => {
        //console.log(result);
        setCities(result);
      });
    }
  }, [formData.postalCode]);

  if (DevisContent.length === 0 && FormStep === 0)
    return (
      <article className="page scroll-container" data-router-view="devis" id="page-devis">
        <MetaTags  title="Location de matériels BTP, demande de devis gratuit - Dymat Loc" description="Réalisez en 3 étapes un devis rapide et gratuit pour la location de vos matériels de chantiers." image=''  name='' />
        <div data-scroll>
          <Ariane name="demande de devis" />
          <section className="page__container">
            <div className="text-intro">
              <h1 className="barlowBold devisVide to-split intro-reveal">
                <span>
                  Demandez votre devis <br />
                  pour la location de matériels BTP
                </span>
              </h1>
              <h2 className="barlowMedium grey to-split intro-reveal">
                <span>Sélectionnez dans nos gammes de matériels, les engins et l'outillage pour vos chantiers</span>
              </h2>
            </div>
            <div className="noResultats-container">
              <nav id="noResultats_nav-notreGamme">
                <ul>
                  {listCategorys && listCategorys.length > 0
                    ? listCategorys.map((category, indexCategory) => {
                        return (
                          <li className="nav-links" key={`${category.name}-${category.id}-${indexCategory}`}>
                            <Link to={`/product-category/${category.slug}`} className="to-split intro-reveal">
                              <span className="barlowSemiBold">{category.name}</span>

                              <SVG select={category.svg_icon} />
                            </Link>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </nav>
            </div>
          </section>
        </div>
      </article>
    );

  return DevisContent ? (
    <article className="page scroll-container" data-router-view="devis" id="page-devis">
      <MetaTags  title="Location de matériels BTP, demande de devis gratuit - Dymat Loc" description="Réalisez en 3 étapes un devis rapide et gratuit pour la location de vos matériels de chantiers." image=''  name='' />
      <div data-scroll>
        <Ariane name="demande de devis" />
        <section className="page__container">
          {FormStep === 0 ? (
            <>
              <div className="text-intro">
                <h1 className="barlowBold devisNonVide">Demandez votre devis pour la location de matériels BTP</h1>
                <nav id="navDemandeDevis">
                  <ul>
                    <li id="produitsLocation" className="active barlowMedium">
                      <span>1</span>Produits sélectionnés
                    </li>
                    <li id="infosLocation" className="barlowMedium">
                      <span>2</span>Informations chantier
                    </li>
                    <li id="infosPerso" className="barlowMedium">
                      <span>3</span>Informations personnelles
                    </li>
                  </ul>
                </nav>
              </div>
              <div id="devis__content">
                {DevisContent.map((product, indexProduct) => (
                  <div key={product.id} className="devisProduit-box" data-id-produit={product.id}>
                    <div className="box">
                      <div className="visuel-produit">
                        <img src={product.image} alt={product.name} className="img-responsive" />
                      </div>
                      <div className="text-devis">
                        <h3 className="barlowMedium">
                          {product.name} <span className="barlowBold">{product.caracteristique}</span>
                        </h3>

                        <p className="sous-titre barlowMedium clear_grey">
                          <strong>{product.marque}</strong>
                        </p>

                        {product.attributes.length !== 0 ? (
                          <div className="options_produit" data-id-produit="{product.id_produit}">
                            <div
                              className={`car_container ${attributesOpen === indexProduct ? "show" : ""} ${
                                AttrOpenedNotSelected.includes(indexProduct) ? "error" : ""
                              }`}
                              onClick={() => {
                                toggleAttributesContainer(indexProduct);
                              }}
                            >
                              {product.attributes.find((e) => e.selected == true) ? (
                                <span className="barlowBold textCaracteristiques">
                                  {product.attributes.find((e) => e.selected == true)?.name}
                                </span>
                              ) : (
                                <span className="barlowBold textBO">Sélectionner votre matériel *</span>
                              )}
                              <div className="car_liste">
                                {product.attributes.map((attribute, indexAttribute) => {
                                  return (
                                    <div
                                      data-id-produit={product.id}
                                      className="devisOption-box"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleSelectAttribute(indexAttribute, indexProduct, attribute.selected);
                                      }}
                                      key={indexAttribute}
                                    >
                                      <div className="option_box">
                                        <div className={`check ${attribute.selected ? "active" : ""}`}>
                                          <span className="checkmark">
                                            <span className="checkmark_stem"></span>
                                            <span className="checkmark_kick"></span>
                                          </span>
                                        </div>
                                        <div className="text-devis">
                                          <p className="barlowMedium">{attribute.name} </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {product.info_devis ? (
                          <p className="info-location barlowMedium">
                            <span className="barlowBold">Inclus dans la location : </span>
                            {product.info_devis}
                          </p>
                        ) : null}
                        {product.options.length > 0 ? (
                          <div className="options_produit" data-id-produit={product.id}>
                            <p className="titre_options barlowBold black">Options de location :</p>
                            <div id="produit__options">
                              {product.options.map((option, indexOption) => (
                                <div
                                  key={option.id}
                                  className="devisOption-box"
                                  onClick={() => handleSelectOption(indexOption, indexProduct, option.value)}
                                >
                                  <div className="option_box">
                                    <div className={`check ${option.value ? "active" : ""}`}>
                                      <span className="checkmark">
                                        <span className="checkmark_stem"></span>
                                        <span className="checkmark_kick"></span>
                                      </span>
                                    </div>
                                    <div className="text-devis">
                                      <p className="barlowMedium">{option.name}</p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <span className="enleverProduit" onClick={() => DeleteItemDevis(indexProduct)}>
                        <SVG select="delete" />
                      </span>
                    </div>
                  </div>
                ))}
                <div className="champs_obligatoires barlowMedium">Champs obligatoires*</div>
                <div
                  className={`btnDevis btn1 textUpperCase white bg_dark_grey barlowMedium ${isButtonDisabled ? "" : "disabled"}`}
                  onClick={() => {
                    setFormStep(1);
                    setIsButtonDisabled(false);
                  }}
                >
                  Continuer
                </div>
              </div>
            </>
          ) : null}

          {FormStep === 1 ? (
            <>
              <div className="text-intro">
                <h1 className="barlowBold devisNonVide">Demandez votre devis pour la location de matériels BTP</h1>
                <nav id="navDemandeDevis">
                  <ul>
                    <li id="produitsLocation" className="active barlowMedium valide" onClick={() => setFormStep(0)}>
                      <span>
                        <SVG select="valide" />
                      </span>
                      Produits sélectionnés
                    </li>
                    <li id="infosLocation" className="active barlowMedium">
                      <span>2</span>Informations chantier
                    </li>
                    <li id="infosPerso" className="barlowMedium">
                      <span>3</span>Informations personnelles
                    </li>
                  </ul>
                </nav>
              </div>
              <form className="barlowMedium dark_grey">
                <div className="champs_container">
                  <div className="halfChamp">
                    <label className="barlowMedium">Date de début*</label>
                    <input
                      id="date_debut_location"
                      name="startDate"
                      type="date"
                      placeholder="jj/mm/aaaa"
                      datatype="dd/mm/aa"
                      value={formData.startDate}
                      onChange={handleChange}
                      required
                      min={new Date().toISOString().split("T")[0]}
                      max={formData.endDate}
                    />
                    <SVG select="calendrier" />
                  </div>

                  <div className="halfChamp">
                    <label className="barlowMedium">Date de fin*</label>
                    <input
                      id="date_fin_location"
                      name="endDate"
                      type="date"
                      placeholder="jj/mm/aaaa"
                      value={formData.endDate}
                      onChange={handleChange}
                      required
                      min={formData.startDate}
                    />
                    <SVG select="calendrier" />
                  </div>
                </div>

                <div className="radios_container">
                  <span className="barlowMedium">Voulez-vous être livré ?*</span>
                  <label className="container">
                    Oui
                    <input
                      type="radio"
                      name="deliveryOption"
                      value="true"
                      checked={formData.deliveryOption === "true"}
                      onChange={handleChange}
                    />
                    <span className="radio_checkmark"></span>
                  </label>
                  <label className="container">
                    Non
                    <input
                      type="radio"
                      name="deliveryOption"
                      value="false"
                      checked={formData.deliveryOption === "false"}
                      onChange={handleChange}
                    />
                    <span className="radio_checkmark"></span>
                  </label>
                </div>

                {formData.deliveryOption === "false" && (
                  <div id="livraisonNonMessage"className="show">
                    Les retraits s’effectuent la veille à partir de 17h ou le matin de la location à partir de 7h.
                  </div>
                )}

                {formData.deliveryOption === "true" && (
                  <div id="livraisonOuiChamps" className="show">
                    <div className="champs_container">
                      <div className="halfChamp">
                        <label htmlFor="heure_depot">Heure de dépôt*</label>
                        <select id="heure_depot" name="depositTime" value={formData.depositTime} onChange={handleChange} required>
                          <option disabled value="">
                            Heure de dépôt*
                          </option>
                          <option value="07:00 - 08:00">07:00 - 08:00</option>
                          <option value="08:00 - 09:00">08:00 - 09:00</option>
                          <option value="09:00 - 10:00">09:00 - 10:00</option>
                          <option value="10:00 - 11:00">10:00 - 11:00</option>
                          <option value="11:00 - 12:00">11:00 - 12:00</option>
                          <option value="12:00 - 13:00">12:00 - 13:00</option>
                          <option value="13:00 - 14:00">13:00 - 14:00</option>
                          <option value="14:00 - 15:00">14:00 - 15:00</option>
                          <option value="15:00 - 16:00">15:00 - 16:00</option>
                          <option value="16:00 - 17:00">16:00 - 17:00</option>
                        </select>
                        <SVG select="hours" />
                      </div>

                      <div className="halfChamp">
                        <label htmlFor="heure_retrait">Heure de retrait*</label>
                        <select id="heure_retrait" name="pickupTime" value={formData.pickupTime} onChange={handleChange} required>
                          <option disabled value="">
                            Heure de retrait*
                          </option>
                          <option value="07:00 - 08:00">07:00 - 08:00</option>
                          <option value="08:00 - 09:00">08:00 - 09:00</option>
                          <option value="09:00 - 10:00">09:00 - 10:00</option>
                          <option value="10:00 - 11:00">10:00 - 11:00</option>
                          <option value="11:00 - 12:00">11:00 - 12:00</option>
                          <option value="12:00 - 13:00">12:00 - 13:00</option>
                          <option value="13:00 - 14:00">13:00 - 14:00</option>
                          <option value="14:00 - 15:00">14:00 - 15:00</option>
                          <option value="15:00 - 16:00">15:00 - 16:00</option>
                          <option value="16:00 - 17:00">16:00 - 17:00</option>
                        </select>
                        <SVG select="hours" />
                      </div>
                    </div>

                    <div className="fullChamp">
                      <input
                        type="text"
                        placeholder="Adresse du chantier*"
                        name="siteAddress"
                        value={formData.siteAddress}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="champs_container">
                      <div className="halfChamp">
                        <input
                          type="text"
                          placeholder="Code postal*"
                          name="postalCode"
                          maxLength={5}
                          value={formData.postalCode}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="halfChamp">
                        <select name="city" value={formData.city} onChange={handleChange} required>
                          <option disabled value="">
                          {cities.length > 0 ? "Ville*" : "Saisisez un code postal"}
                          </option>
                          {/* Remplir le select avec les options des villes */}
                          {cities.length > 0
                            ? cities.map((ville) => (
                                <option key={ville.nomCommune} value={ville.nomCommune}>
                                  {ville.nomCommune}
                                </option>
                              ))
                            : []}
                        </select>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className={`btnDevis btn1 textUpperCase white bg_dark_grey barlowMedium ${isButtonDisabled ? "" : "disabled"}`}
                  onClick={() => {
                    setFormStep(2);
                    setIsButtonDisabled(false);
                  }}
                >
                  Continuer
                </div>
              </form>
            </>
          ) : null}

          {FormStep === 2 ? (
            <>
              <div className="text-intro">
                <h1 className="barlowBold devisNonVide">Demandez votre devis pour la location de matériels BTP</h1>
                <nav id="navDemandeDevis">
                  <ul>
                    <li id="produitsLocation" className="active barlowMedium valide" onClick={() => setFormStep(0)}>
                      <span>
                        <SVG select="valide" />
                      </span>
                      Produits sélectionnés
                    </li>
                    <li id="infosLocation" className="active barlowMedium valide" onClick={() => setFormStep(1)}>
                      <span>
                        <SVG select="valide" />
                      </span>
                      Informations chantier
                    </li>
                    <li id="infosPerso" className="active barlowMedium">
                      <span>3</span>Informations personnelles
                    </li>
                  </ul>
                </nav>
              </div>
              <form id="formInfosPersonnelles" className="barlowMedium dark_grey" onSubmit={handleSubmit}>
                <input id="g-recaptcha-response" type="hidden" name="g-recaptcha-response" value="" />
                <input type="hidden" name="token" value="your-token-here" />

                <div className="radios_container">
                  <span className="barlowMedium">Civilité</span>
                  <label className="container">
                    Madame
                    <input
                      type="radio"
                      name="civility"
                      value="Madame"
                      checked={formData.civility === "Madame"}
                      onChange={handleChange}
                    />
                    <span className="radio_checkmark"></span>
                  </label>
                  <label className="container">
                    Monsieur
                    <input
                      type="radio"
                      name="civility"
                      value="Monsieur"
                      checked={formData.civility === "Monsieur"}
                      onChange={handleChange}
                    />
                    <span className="radio_checkmark"></span>
                  </label>
                </div>

                <div className="champs_container">
                  <div className="halfChamp">
                    <input
                      type="text"
                      placeholder="Société"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="halfChamp">
                    <input type="text" placeholder="Nom*" name="name" value={formData.name} onChange={handleChange} required />
                  </div>
                </div>

                <div className="champs_container">
                  <div className="halfChamp">
                    <input
                      type="email"
                      placeholder="Email*"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="halfChamp">
                    <input
                      type="tel"
                      placeholder="Téléphone*"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="radios_container contacterPar">
                  <span className="barlowMedium">Vous souhaitez être contacté par*</span>
                  <label className="container">
                    Email
                    <input
                      type="radio"
                      name="contactPreference"
                      value="email"
                      checked={formData.contactPreference === "email"}
                      onChange={handleChange}
                    />
                    <span className="radio_checkmark"></span>
                  </label>
                  <label className="container">
                    Téléphone
                    <input
                      type="radio"
                      name="contactPreference"
                      value="telephone"
                      checked={formData.contactPreference === "telephone"}
                      onChange={handleChange}
                    />
                    <span className="radio_checkmark"></span>
                  </label>
                </div>

                <div className="radios_container confidentialite">
                  <label className="container">
                    <input type="checkbox" name="acceptPolicy" checked={formData.acceptPolicy} onChange={handleChange} required />
                    <span className="radio_checkmark"></span>
                  </label>
                  <span className="barlowMedium">
                    J’ai lu et j’accepte la
                    <a href="https://www.dymatloc.com/mentions-legales.html" className="black underline" target="_blank">
                      politique de confidentialité
                    </a>
                    *
                  </span>
                </div>

                <div className="champs_obligatoires barlowMedium">Champs obligatoires*</div>
                <div
                  className={`btnDevis btn1 textUpperCase black bg_jaune barlowBold ${isButtonDisabled ? "" : "disabled"}`}
                  onClick={handleSubmit}
                >
                  Envoyer ma demande
                </div>
              </form>
            </>
          ) : null}

          {FormStep === 3 ? (
            <div className="text-intro">
              <h1 className="barlowBold">Votre demande a été envoyée !</h1>
              <h2 className="barlowMedium grey">
                Merci de votre confiance. Nous mettons tout en oeuvre pour répondre dans les meilleurs délais à votre demande de
                devis.
              </h2>
              <div id="devis__content" className="devis_recap">
                <div id="devisMateriels">
                  <h2 className="barlowBold dark_grey">Matériel{formData.listItems.length > 1 ? "s" : ""}</h2>
                  {formData.listItems.map((produit) => (
                    <div key={produit.id} className="block">
                      <p className="barlowMedium">
                        <strong>
                          {produit.name} {produit.caracteristique && ` ${produit.caracteristique}`}{" "}
                          {produit.marque && ` - ${produit.marque}`}
                        </strong>
                      </p>

                      {produit.attributes.length > 0 && (
                        <div className="block">
                          <p className="barlowMedium">
                            {produit.attributes.map((attribut) => ( attribut.selected ? <span key={attribut.name}>1x {attribut.name}&nbsp;</span> : "")).filter(attribut=>attribut)}
                          </p>
                        </div>
                      )}

                      {produit.info_devis && (
                        <div className="block">
                          <p className="barlowMedium">Inclus dans la location : {produit.info_devis}</p>
                        </div>
                      )}

                      {produit.options.length > 0 &&
                        produit.options.map((option) => (
                          <div key={option.id} className="block">
                            <p className="barlowMedium">En option : {option.name}</p>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>

                {/* Informations chantier */}
                <div id="infosChantier">
                  <h2 className="barlowBold dark_grey">Informations chantier</h2>
                  <div className="block">
                    <p className="barlowMedium black">Date de début : {formData.startDate}</p>
                  </div>
                  <div className="block">
                    <p className="barlowMedium black">Date de fin : {formData.endDate}</p>
                  </div>
                  <div className="block">
                    <p className="barlowMedium black">
                      Voulez-vous être livré ? {formData.deliveryOption === "oui" ? "Oui" : "Non"}
                    </p>
                  </div>
                  {formData.deliveryOption === "oui" ? (
                    <>
                      <div className="block">
                        <p className="barlowMedium black">Heure dépôt : {formData.depositTime}</p>
                      </div>
                      <div className="block">
                        <p className="barlowMedium black">Heure retrait : {formData.pickupTime}</p>
                      </div>
                      <div className="block">
                        <p className="barlowMedium black">Adresse chantier : {formData.siteAddress}</p>
                      </div>
                      <div className="block">
                        <p className="barlowMedium black">Code postal : {formData.postalCode}</p>
                      </div>
                      <div className="block">
                        <p className="barlowMedium black">Ville : {formData.city}</p>
                      </div>
                    </>
                  ) : (
                    <div className="block">
                      <p className="barlowMedium black">
                        Les retraits s’effectuent la veille à partir de 17h ou le matin de la location à partir de 7h.
                      </p>
                    </div>
                  )}
                </div>

                {/* Informations personnelles */}
                <div id="infosPerso">
                  <h2 className="barlowBold dark_grey">Informations personnelles</h2>
                  {formData.civility ? (
                    <div className="block">
                      <p className="barlowMedium black">Civilité : {formData.civility}</p>
                    </div>
                  ) : null}
                  <div className="block">
                    <p className="barlowMedium black">Nom : {formData.name}</p>
                  </div>
                  <div className="block">
                    <p className="barlowMedium black">Email : {formData.email}</p>
                  </div>
                  <div className="block">
                    <p className="barlowMedium black">Téléphone : {formData.phone}</p>
                  </div>
                  {formData.companyName ? (
                    <div className="block">
                      <p className="barlowMedium black">Société : {formData.companyName}</p>
                    </div>
                  ) : null}
                  <div className="block">
                    <p className="barlowMedium black">
                      Vous souhaitez être contacté par : {formData.contactPreference === "Email" ? "Email" : "Téléphone"}
                    </p>
                  </div>
                </div>
              </div>
              <Link className="btnDevis btn1 textUpperCase white bg_dark_grey barlowMedium" to="/" title="Page d'accueil">
                Un autre besoin ?
              </Link>
            </div>
          ) : null}
        </section>
      </div>
    </article>
  ) : null;
};

export default transition(PageDevis);
