import { FC } from "react";
import { Link } from "react-router-dom";

const Ariane: FC<{ name: string; }> = ({ name }) => {
  return (
    <div className="ariane">
      <ul className="wrapper">
        <li>
          <Link className="textUpperCase barlowBold black" to="/">
            Accueil
          </Link>
        </li>
        <li>
          <Link className="textUpperCase barlowMedium black" to="#">
            {name}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Ariane;
