const fetchGetSearchProducts = async (info: string) => {
  var data: any = "";
  try {
    const response = await fetch(
      `/wp-json/v1/search-products?search=${encodeURIComponent(info)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    data = { data: await response.json(), status: response.status };
  } catch {
    return false;
  }
  // console.log(data);
  return data;
};
export default fetchGetSearchProducts;
