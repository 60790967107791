import { FC, useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SVG from "../../components/svg";
import transition from "../../components/transition";
import { animationScrollPage, splitLines } from "../../utils/animations";
import { isValidReq } from "../../utils/check-status";
import fetchGetPageFields from "../../utils/request/wp-get-page-fields";
import TypeListCategory from "../../utils/types/category-list";
import MetaTags from "../../components/metatags";
import "./home-page.css";

interface subFooterElement {
  title: string;
  para: string;
}

interface HPdata {
  title_banner: string;
  image_banner_desk: string;
  image_banner_mob: string;
  title_gamme: string;
  para_gamme: string;
  element_1: subFooterElement;
  element_2: subFooterElement;
  element_3: subFooterElement;
  element_4: subFooterElement;
  metatitle: string;
  metadescription: string;
}

const HomePage: FC<{ listCategorys: TypeListCategory[] }> = ({ listCategorys }) => {
  const [data, setData] = useState<HPdata>(); /* 
  useEffect(() => {
    animationScrollPage();
  }, [data]); */

  const navigate = useNavigate();

  const [needleSearch, setNeedleSearch] = useState<string>("");

  const wpPageID: number = 83;
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });

    fetchGetPageFields(wpPageID).then((result) => {
      if (isValidReq(result.status)) {
        setData(result.data);
      }
    });
  }, []);

  useLayoutEffect(() => {
    if (data) {
      Array.prototype.forEach.call(document.querySelectorAll(".to-split span"), function (e, index) {
        splitLines(e, '<span class="line-container"><span>', "</span></span>");
      });
      animationScrollPage();
    }
  }, [data]);

  return data ? (
    <article id="homepage" data-router-view="home" className="scroll-container">
      <MetaTags  title={data.metatitle} description={data.metadescription} image=''  name='' />
      <div data-scroll>
        <section className="bandeau-container">
          <picture>
            <source srcSet={data.image_banner_desk} media="(min-width:992px)" />
            <source srcSet={data.image_banner_mob} media="(max-width:991px)" />
            <img id="image_bg" src={data.image_banner_desk} alt="" role="presentation" className="img-responsive lazy" />
          </picture>

          <div className="text-container">
            <h1 className="white barlowRegular to-split intro-reveal" aria-label={data.title_banner}>
              <span aria-hidden="true">{data.title_banner}</span>
            </h1>
            <div id="input-search" className="to-animate intro-reveal">
              <form
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  navigate(`/search/${needleSearch}`);
                }}
              >
                <input
                  type="search"
                  className="barlowMedium"
                  id="site-search"
                  name="q"
                  aria-label="Que recherchez-vous ?"
                  placeholder="Que recherchez-vous ?"
                  onChange={(e) => setNeedleSearch(e.target.value)}
                />
                <div id="produits_trouves_container_bandeau" className="produits_trouves_container">
                  <ul className="liste_produits barlowBold"></ul>
                </div>
                <button id="btnSiteSearchHome" className="flexbox-end-center">
                  <SVG select="search" />
                </button>
              </form>
            </div>
          </div>

          <div id="scroller">
            <div className="arrow arrow-first"></div>
            <div className="arrow arrow-second"></div>
          </div>
        </section>

        <section id="notre-gamme_container">
          <div className="text-intro">
            <p className="barlowBold to-split scroll-reveal" aria-label="Notre gamme">
              <span aria-hidden="true">{data.title_gamme}</span>
            </p>
            <h2 className="barlowMedium grey to-split scroll-reveal" aria-label={data.para_gamme}>
              <span aria-hidden="true">{data.para_gamme}</span>
            </h2>
          </div>
          <div className="produis-container">
            {listCategorys.map((categorie, index) => (
              <div key={index} className="produit-box">
                <Link
                  title={`${categorie.name} : découvrez notre offre de location de matériels.`}
                  to={`/product-category/${categorie.slug}`}
                >
                  <div className="reveal-block left" data-aos="reveal-left"></div>
                  <div className="box">
                    <h3 className="barlowBold">{categorie.name}</h3>
                    <p className="sous-titre barlowMedium grey">
                      <strong>{categorie.card_description}</strong>
                    </p>
                    <div className="visuel-produit">
                      <img
                        alt={categorie.name}
                        src={categorie.card_image}
                        title={`${categorie.name} : découvrez notre offre de location de matériels.`}
                        className="img-responsive"
                      />
                    </div>
                    <div className="link-page">
                      <span className="barlowBold">Découvrir</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15.599" height="13" viewBox="0 0 15.599 13">
                        <defs></defs>
                        <path
                          className="aarrow"
                          d="M8.65,17.85H21.617L16.652,13.12a.65.65,0,0,1,.9-.942l5.67,5.4a1.3,1.3,0,0,1-.011,1.85l-5.659,5.39a.65.65,0,1,1-.9-.941l4.986-4.729H8.65a.65.65,0,0,1,0-1.3Z"
                          transform="translate(-8 -12)"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </section>
        <section className="pre-footer">
          <div className="nos-qualites_container grid-24">
            {Object.entries(data)
              .filter(([key]) => key.startsWith("element_"))
              .map(([key, elem], index) => {
                const elementItem = elem as subFooterElement;
                if (elementItem.title === "" || elementItem.para === "") return;
                return (
                  <div className="qualite-container" key={`${key}-${index}`}>
                    <SVG select="hexa_valid" />
                    <h2 className="titre-qualite textUpperCase barlowBold to-split scroll-reveal" aria-label={elementItem.title}>
                      <span aria-hidden="true">{elementItem.title}</span>
                    </h2>
                    <p className="barlowMedium to-split scroll-reveal" aria-label={elementItem.title}>
                      <span aria-hidden="true">{elementItem.para}</span>
                    </p>
                  </div>
                );
              })}
          </div>
        </section>
      </div>
    </article>
  ) : null;
};

export default transition(HomePage);
