import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import transition from "../../components/transition";
import "./not-found.css";
import Ariane from "../../components/ariane";
import MetaTags from "../../components/metatags";


const PageNotFound: FunctionComponent = () => {
  return (
    <article className="page scroll-container" data-router-view="custom">
      <MetaTags  title="404 - Vous êtes perdu, retrouvez votre matériel à louer - Dymat Loc" description="Page 404" image=''  name='' />
      <Ariane name="Page Introuvable"/>
      <section className="page__container">
        <div className="text-intro">
          <h1 className="visually-hidden">404 - Not Found</h1>
          <div className="titre barlowBold">Désolé !</div>
          <h2 className="barlowMedium dark_grey">
            Le lien que vous avez suivi est peut être incorrect ou la page peut avoir été supprimée.
          </h2>
          <Link to="/">
            <div className="btnDevis btn1 textUpperCase black bg_jaune barlowBold">Retour à l'accueil</div>
          </Link>
          <div className="image_404">
            <picture>
              <img src="https://www.dymatloc.com/wp-content/uploads/2024/09/image404.png" alt="" role="presentation" className="img-responsive" />
            </picture>
          </div>
        </div>
      </section>
    </article>
  );
};

export default transition(PageNotFound);
