import { FC, useEffect, useLayoutEffect, useState } from "react";
import Ariane from "../../components/ariane";
import SVG from "../../components/svg";
import transition from "../../components/transition";
import { animationScrollPage, splitLines } from "../../utils/animations";
import { isValidReq } from "../../utils/check-status";
import fetchGetPageFields from "../../utils/request/wp-get-page-fields";
import MetaTags from "../../components/metatags";
import "./qui-sommes-nous.css";
interface QSNData {
  title_grp_1: string;
  para_grp_1: string;
  img_grp_1: string; // Je vois que tu as un champ image même s'il est vide
  title_grp_2: string;
  elem_1_grp_2: string;
  elem_2_grp_2: string;
  elem_3_grp_2: string;
  elem_4_grp_2: string;
  elem_5_grp_2: string;
  title_end: string;
  metatitle: string;
  metadescription: string;
}

const QSN: FC = () => {
  const wpPageID: number = 89;
  const [data, setData] = useState<QSNData>();

  useLayoutEffect(() => {
    if (data) {
      Array.prototype.forEach.call(document.querySelectorAll(".to-split span"), function (e, index) {
        splitLines(e, '<span class="line-container"><span>', "</span></span>");
      });
      animationScrollPage();
    }
  }, [data]);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    fetchGetPageFields(wpPageID).then((result) => {
      if (isValidReq(result.status)) {
        setData(result.data);
      }
    });
  }, []);

  return data ? (
    <article className="page scroll-container" data-router-view="custom" id="page-qsn">
      <MetaTags  title={data.metatitle} description={data.metadescription} image=''  name='' />
      <Ariane name="Qui sommes-nous ?" />
      <section className="page__container">
        <div className="text-intro">
          <div className="paragraphe">
            <div className="text_box">
              <div className="text_container barlowMedium">
                <p
                  className="barlowBold black titre to-split intro-reveal"
                  aria-label="Découvrir Dymat Loc"
                  dangerouslySetInnerHTML={{ __html: data.title_grp_1 }}
                />
                <p className="to-split intro-reveal">
                  {data.para_grp_1.split("\r\n\r\n").map((para, index) => (
                    <span key={index} aria-hidden="true">
                      {para}
                      <br />
                    </span>
                  ))}
                </p>
              </div>
            </div>

            {data.img_grp_1 && (
              <div className="image_box">
                <div className="image_paragraphe">
                  <div className="reveal-block left intro-reveal" data-aos="reveal-left"></div>
                  <picture>
                    <img src={data.img_grp_1} alt="Dymat Loc Image" role="presentation" className="img-responsive" />
                  </picture>
                </div>
              </div>
            )}
          </div>

          <div className="paragraphe2">
            <h1
              className="barlowBold black to-split scroll-reveal"
              aria-label="Pourquoi choisir Dymat Loc ?"
              dangerouslySetInnerHTML={{ __html: data.title_grp_2 }}
            />

            <div className="liste barlowMedium to-animateSVG">
              <div className="box_liste">
                <ul>
                  <li>
                    <SVG select={"hexa_valid"} />
                    <h2 className="to-split scroll-reveal" aria-label={data.elem_1_grp_2}>
                      <span aria-hidden="true">{data.elem_1_grp_2}</span>
                    </h2>
                  </li>
                  <li>
                    <SVG select={"hexa_valid"} />
                    <h2 className="to-split scroll-reveal" aria-label={data.elem_2_grp_2}>
                      <span aria-hidden="true">{data.elem_2_grp_2}</span>
                    </h2>
                  </li>
                  <li>
                    <SVG select={"hexa_valid"} />
                    <h2 className="to-split scroll-reveal" aria-label={data.elem_3_grp_2}>
                      <span aria-hidden="true">{data.elem_3_grp_2}</span>
                    </h2>
                  </li>
                </ul>
              </div>
              <div className="box_liste">
                <ul>
                  <li>
                    <SVG select={"hexa_valid"} />
                    <h2 className="to-split scroll-reveal" aria-label={data.elem_4_grp_2}>
                      <span aria-hidden="true">{data.elem_4_grp_2}</span>
                    </h2>
                  </li>
                  <li>
                    <SVG select={"hexa_valid"} />
                    <h2 className="to-split scroll-reveal" aria-label={data.elem_5_grp_2}>
                      <span aria-hidden="true">{data.elem_5_grp_2}</span>
                    </h2>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="paragraphe3">
          <p
            className="barlowMedium black to-split scroll-reveal"
            aria-label="Alors n’hésitez plus, faites appel à Dymat Loc !"
            dangerouslySetInnerHTML={{ __html: data.title_end }}
          />
        </div>
      </section>
    </article>
  ) : null;
};

export default transition(QSN);
