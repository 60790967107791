import App from "./app";
import { createRoot } from "react-dom/client";

let rootElement = document.getElementById("root");

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<App />);

  setTimeout(() => {
    if (rootElement) {
      rootElement.style.background = "unset";
      rootElement.style.display = "unset";
      rootElement.style.height = "unset";
      rootElement.style.width = "unset";
      rootElement.style.top = "unset";
      rootElement.style.left = "unset";
    }
  }, 100);
}
