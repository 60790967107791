import { FC } from "react";
import TypeCompleteProduct from "../utils/types/product-complete";
import { Link } from "react-router-dom";
import SVG from "./svg";

const Card: FC<{ product: TypeCompleteProduct; fn: Function }> = ({ product, fn }) => {
  return (
    <div className="produit-box">
      <div className="reveal-block left" data-aos="reveal-left"></div>
      <div className="box">
        <div className="text_produit">
          <h3 className="barlowMedium">
            {product.name} <br />
            {product.caracteristique !== "" ? (
              <span className="sous-titre barlowBold">
                <strong>{product.caracteristique}</strong>
              </span>
            ) : null}
          </h3>
        </div>
        <div className="visuel-produit categorie-page">
          <img
            src={product.image}
            data-src={product.image}
            data-srcset={product.image}
            title={product.name}
            className="img-responsive lazy"
          />
        </div>
        <div className="link-produit">
          {product.pdf ? (
            <Link
              className="fiche_tech"
              to={product.pdf}
              rel="noopener"
              target="_blank"
              title={`Télécharger la fiche technique : ${product.name} ${product.caracteristique} ${product.marque}`}
            >
              <span className="barlowBold textUpperCase">Fiche technique</span>
              <SVG select="fiche"/>
            </Link>
          ) : null}
          <a
            data-router-disabled
            className={`ajouter_au_devis ${product.pdf ? null : "fullWidth"}`}
            onClick={() => fn(product)}
            title={`Ajouter ${product.name} ${product.caracteristique} ${product.marque} à ma demande de devis`}
          >
            <span className="barlowBold textUpperCase white">Ajouter au devis</span>
            <SVG select="arrow_left"/>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Card;
