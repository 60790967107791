import { FC, useEffect, useState } from "react";
import transition from "../../components/transition";
import "./plan-site.css";
import TypeListCategory from "../../utils/types/category-list";
import { Link } from "react-router-dom";
import { isValidReq } from "../../utils/check-status";
import fetchGetPageFields from "../../utils/request/wp-get-page-fields";
import Ariane from "../../components/ariane";
import MetaTags from "../../components/metatags";

interface PSdata {
  image: string;
  metatitle: string;
  metadescription: string;
}

const PlanSite: FC<{ listCategorys: TypeListCategory[] }> = ({ listCategorys }) => {

  const [data, setData] = useState<PSdata>();
  const wpPageID: number = 87;
  useEffect(() => {
    fetchGetPageFields(wpPageID).then((result) => {
      if (isValidReq(result.status)) {
        setData(result.data);
      }
    });
  }, []);

  
  return data ? (
    <article className="page" id="page-plan-site">
    <MetaTags  title={data.metatitle} description={data.metadescription} image=''  name='' />
    <section className="page__container">
      <Ariane name="Plan du site"/>
      <div className="box_content">
        <div className="menus">
          <div className="menu-notreGamme">
            <div className="menu-container">
              <div className="titreMenuPlanDuSite barlowBold">Notre gamme</div>
              <nav aria-label="Menu Notre gamme" className="nav-notreGammeSitemap">
                <ul>
                  {listCategorys?.map((e, i) => {
                    return <li className='nav-links' key={`${i}-${e.id}`}>
                    <Link to={`/product-category/${e.id}`}>
                        <span className='barlowSemiBold'>{e.name}</span>
                    </Link>
                    </li>;
                  })}
                </ul>
              </nav>
            </div>
          </div>
          <div className="menu-dymatloc">
            <div className="menu-container">
              <div className="titreMenuPlanDuSite dark_grey barlowBold">
                Dymat <span className="jaune">Loc</span>
              </div>
              <nav aria-label="Menu Dymat Loc" className="nav-DymatlocSitemap">
                <ul>
                  <li className="nav-links">
                    <Link to="/qui-sommes-nous">
                      <span className=" barlowSemiBold">Qui sommes-nous ?</span>
                    </Link>
                  </li>
                  <li className="nav-links">
                    <Link to="/faq">
                      <span className=" barlowSemiBold">FAQ</span>
                    </Link>
                  </li>
                  <li className="nav-links">
                    <Link to="/agence">
                      <span className=" barlowSemiBold">Nos agences</span>
                    </Link>
                  </li>

                  <li className="nav-links">
                    <Link to="/mentions-legales">
                      <span className=" barlowSemiBold">Mentions legales</span>
                    </Link>
                  </li>
                  <li className="nav-links">
                    <Link to="/contact">
                      <span className=" barlowSemiBold">Contactez-nous</span>
                    </Link>
                  </li>
                  <li className="nav-links">
                    <Link to="dyn/pdf/cgv.pdf" rel="noopener" target="_blank" title="CGV">
                      <span className=" barlowSemiBold">CGV</span>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="text-intro">
          <h1 className="titre barlowBold">
            Plan <br />
            du site
          </h1>
          <div className="image_PlanDuSite">
            <picture>
              <source srcSet={data.image} media="(min-width:992px)" />
              <img
                src={data.image}
                alt=""
                role="presentation"
                className="img-responsive"
              />
            </picture>
          </div>
        </div>
      </div>
    </section></article>
  ) : null;
};

export default transition(PlanSite);
