import { FC, useEffect, useState } from "react";
import transition from "../components/transition";
import { animationScrollPage } from "../utils/animations";
import { isValidReq } from "../utils/check-status";
import fetchGetPageContent from "../utils/request/wp-get-page-content";
import MetaTags from "../components/metatags";

import Ariane from "../components/ariane";

interface TCdata {
  html: string,
  metatitle: string;
  metadescription: string;
}

const NosConseils: FC<{ title: string; wpPageId: number }> = ({ title, wpPageId }) => {
  const [data, setData] = useState<TCdata>();
  useEffect(() => {
    animationScrollPage();
  }, [data]);

  useEffect(() => {
    fetchGetPageContent(wpPageId).then((result) => {
      if (isValidReq(result.status)) {
        setData(result.data);
      }
    });
  }, []);
  return data ? (
    <article className="page categorie conseil scroll-container" data-router-view="custom" id="template-conseil">
      <MetaTags  title={data.metatitle} description={data.metadescription} image=''  name='' />
      <Ariane name="Conseil"/>
      <section className="bandeau-container bandeau-compactage">
        <div className="padding__container">
          <div className="height__container">
            <picture>
              <source
                srcSet="https://www.dymatloc.com/wp-content/uploads/2024/09/page_compactage-1280.jpg"
                media="(min-width:1920px)"
              />
              <source
                srcSet="https://www.dymatloc.com/wp-content/uploads/2024/09/page_compactage-1600.jpg"
                media="(min-width:1440px) and (max-width:1919px)"
              />
              <source
                srcSet="https://www.dymatloc.com/wp-content/uploads/2024/09/page_compactage-1280.jpg"
                media="(min-width:992px) and (max-width:1439px)"
              />
              <source
                srcSet="https://www.dymatloc.com/wp-content/uploads/2024/09/page_compactage.jpg"
                media="(max-width:991px)"
              />
              <img
                id="image_bg"
                src=""
                data-src="https://www.dymatloc.com/wp-content/uploads/2024/09/page_compactage-1280.jpg"
                data-srcset="https://www.dymatloc.com/wp-content/uploads/2024/09/page_compactage-1280.jpg"
                alt="Location de matériel de compactage : rouleau compresseur 2 billes JCB"
                className="img-responsive lazy"
              />
            </picture>
            <div className="text-title-compactage-container">
              <h1 className="white barlowRegular to-split intro-reveal" aria-label={title}>
                <span aria-hidden="true">
                  <span aria-hidden="true">{title}</span>
                </span>
              </h1>
            </div>
            <div id="scroller">
              <div className="arrow arrow-first"></div>
              <div className="arrow arrow-second"></div>
            </div>
          </div>
        </div>
      </section>
      <section id="page-cluster-container">
        <div dangerouslySetInnerHTML={{ __html: data.html }}></div>
      </section>
    </article>
  ) : null;
};

export default transition(NosConseils);
