import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Ariane from "../../components/ariane";
import transition from "../../components/transition";
import { animationScrollPage } from "../../utils/animations";
import { isValidReq } from "../../utils/check-status";
import MetaTags from "../../components/metatags";

import {
  sanitizeInput,
  validateComment,
  validateCompany,
  validateEmail,
  validateName,
  validatePhone,
} from "../../utils/field-verification";
import fetchGetPageFields from "../../utils/request/wp-get-page-fields";
import fetchPostFormContact from "../../utils/request/wp-post-form-contact";
import TypeDataContact from "../../utils/types/data-form-contact";
import "./contact.css";

interface Contactdata {
  metatitle: string;
  metadescription: string;
}

const Contact: FC = () => {
  const [data, setData] = useState<Contactdata>();
  useEffect(() => {
    animationScrollPage();
  }, [data]);

  const navigate = useNavigate();
  const wpPageID: number = 79;
  useEffect(() => {
    fetchGetPageFields(wpPageID).then((result) => {
      if (isValidReq(result.status)) {
        //console.log(result.data);
        setData(result.data);
      }
    });
  }, []);

  const [formData, setFormData] = useState<TypeDataContact>({
    civility: "",
    company: "",
    name: "",
    email: "",
    phone: "",
    agence: "",
    comment: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    const checked = type === "checkbox" ? (e.target as HTMLInputElement).checked : undefined;

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : sanitizeInput(value),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      alert("Veuillez entrer une adresse email valide.");
      return;
    }

    if (!validateName(formData.name)) {
      alert("Veuillez entrer un nom valide.");
      return;
    }

    if (!validatePhone(formData.phone)) {
      alert("Veuillez entrer un numero de telephone valide.");
      return;
    }

    if (!validateCompany(formData.company)) {
      alert("Veuillez entrer un nom de société valide.");
      return;
    }

    if (!validateComment(formData.comment)) {
      alert("Veuillez entrer un commentaire valide.");
      return;
    }

    fetchPostFormContact(formData).then((result) => {
      if (isValidReq(result.status)) {
        navigate("/");
      } else console.log(result);
    });
  };

  return data ? (
    <article className="page scroll-container" data-router-view="contactezNous" id="page-devis">
      <MetaTags  title={data.metatitle} description={data.metadescription} image=''  name='' />
      <div data-scroll>
        <Ariane name="Contactez nous" />
        <section className="page__container">
          <div className="text-intro">
            <h1 className="barlowBold">Contactez-nous</h1>
            <h2 className="barlowMedium grey">
              Veuillez remplir le formulaire ci-dessous et nous vous répondrons dans les plus brefs délais.
            </h2>
          </div>
          <div id="devis__content">
            <form id="formContactezNous" className="barlowMedium dark_grey" onSubmit={handleSubmit}>
              <input id="g-recaptcha-response" type="hidden" name="g-recaptcha-response" value="" />
              <input type="hidden" name="token" value="" />

              <div className="radios_container">
                <span className="barlowMedium">Civilité</span>
                <label className="container">
                  Madame
                  <input
                    id="civiliteMadame"
                    type="radio"
                    name="civility"
                    value="Madame"
                    checked={formData.civility === "Madame"}
                    onChange={handleChange}
                  />
                  <span className="radio_checkmark"></span>
                </label>
                <label className="container">
                  Monsieur
                  <input
                    id="civiliteMonsieur"
                    type="radio"
                    name="civility"
                    value="Monsieur"
                    checked={formData.civility === "Monsieur"}
                    onChange={handleChange}
                  />
                  <span className="radio_checkmark"></span>
                </label>
              </div>

              <div className="champs_container">
                <div className="halfChamp">
                  <input
                    type="text"
                    placeholder="Société"
                    name="company"
                    id="societe"
                    value={formData.company}
                    onChange={handleChange}
                  />
                </div>
                <div className="halfChamp">
                  <input
                    type="text"
                    placeholder="Nom*"
                    name="name"
                    id="nom"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="champs_container">
                <div className="halfChamp">
                  <input
                    type="email"
                    placeholder="Email*"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="halfChamp">
                  <input
                    type="tel"
                    placeholder="Téléphone*"
                    name="phone"
                    id="telephone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="fullChamp">
                <select name="agence" id="agence_location" value={formData.agence} onChange={handleChange} required>
                  <option selected disabled hidden value="">
                    Sélectionnez votre agence de location*
                  </option>
                  <option value="Dunkerque">Dunkerque</option>
                  <option value="Valenciennes">Valenciennes</option>
                  <option value="St Quentin">St Quentin</option>
                </select>
              </div>

              <div className="fullChamp" style={{ marginBottom: 0 }}>
                <label htmlFor="comment">Votre demande*</label>
                <textarea
                  placeholder="Pouvez-vous nous en dire plus ?"
                  id="raison_demande"
                  name="comment"
                  value={formData.comment}
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className="radios_container confidentialite">
                <label className="container">
                  <input id="accepterPolitique" type="checkbox" name="acceptance" onChange={handleChange} required />
                  <span className="radio_checkmark"></span>
                </label>
                <span className="barlowMedium">
                  J’ai lu et j’accepte la
                  <a href="/mentions-legales.html" className="black underline" target="_blank">
                    politique de confidentialité
                  </a>
                  *
                </span>
              </div>

              <div className="champs_obligatoires barlowMedium">Champs obligatoires*</div>
            </form>

            <div
              className="btnDevis btn1 textUpperCase black bg_jaune barlowBold"
              onClick={() => handleSubmit}
            >
              Envoyer ma demande
            </div>
          </div>
        </section>
      </div>
    </article>
  ) : null;
};

export default transition(Contact);
