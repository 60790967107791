import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "./main-logo";
import { animationScrollPage } from "../utils/animations";
import SVG from "./svg";



const Footer: FC = () => {
  const siteUrl = "/"; 

  const backToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };



  return (
    <footer>
      <div className="logo-footer">
        <Link to="/" title="Dymat Loc">
          <svg id="logo" xmlns="http://www.w3.org/2000/svg" width="210" height="54.397" viewBox="0 0 210 54.397">
            <Logo />
          </svg>
        </Link>
      </div>

      <div id="liens1-container">
        <nav aria-label="Menu footer" id="navFooter-Dymatloc">
          <ul>
            <li className="nav-links">
              <Link to="/contact" title="Contactez-nous">
                <span className="white barlowSemiBold">Contactez-nous</span>
                <SVG select="contact" />
              </Link>
            </li>
            <li className="nav-links">
              <Link to="/agence" title="Trouver votre agence">
                <span className="white barlowSemiBold">Trouver votre agence</span>
                <SVG select="agence" />
              </Link>
            </li>
            <li className="nav-links">
              <Link
                to="https://www.facebook.com/Dymat-loc-Philmat-Philippe-tp-154471898485487/"
                rel="noopener"
                target="_blank"
                title="Suivez nous sur Facebook"
              >
                <span className="white barlowSemiBold">Suivez nous</span>
                <SVG select="facebook" />
              </Link>
            </li>
            <li className="nav-links">
              <Link
                to={`/wp-content/uploads/2024/09/catalogue-vente-materiels-dymatloc.pdf`}
                rel="noopener"
                target="_blank"
                title="Notre catalogue de ventes"
              >
                <span className="white barlowSemiBold">Nos matériels en vente</span>
                <svg xmlns="http:
                www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                  <SVG select="page" />
                </svg>
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      <div id="liens2-container">
        <nav aria-label="Menu footer secondaire" id="navFooterSecondaire-Dymatloc">
          <ul>
            <li className="nav-links">
              <Link to="/plan-site" title="Plan du site">
                <span className="white barlowMedium">Plan du site</span>
              </Link>
            </li>
            <li className="nav-links">
              <Link to={`https://www.dymatloc.com/wp-content/uploads/2024/09/cgv.pdf`} rel="noopener" target="_blank" title="CGV">
                <span className="white barlowMedium">CGV</span>
              </Link>
            </li>
            <li className="nav-links">
              <Link to="/mentions-legales" title="Mentions légales">
                <span className="white barlowMedium">Mentions légales</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      <div id="btnBackToTop" onClick={backToTop}>
        <SVG select="arrow_up" />
      </div>
    </footer>
  );
};

export default Footer;
