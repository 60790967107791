import { FC, useEffect, useState } from "react";
import transition from "../../components/transition";
import { animationScrollPage } from "../../utils/animations";
import { isValidReq } from "../../utils/check-status";
import fetchGetPageFields from "../../utils/request/wp-get-page-fields";
import MetaTags from "../../components/metatags";

import "./agence.css";
import Ariane from "../../components/ariane";
interface Agence {
  titre: string;
  address: string;
  tel: string;
  fax: string;
}

interface NosAgencesType {
  image_carte: string;
  agence_1: Agence;
  agence_2: Agence;
  agence_3: Agence;
  metatitle: string;
  metadescription: string;
}
const Agence: FC = () => {
  const [data, setData] = useState<NosAgencesType>();
  useEffect(() => {
    animationScrollPage();
  }, [data]);

  const wpPageID: number = 77;
  useEffect(() => {
    fetchGetPageFields(wpPageID).then((result) => {
      if (isValidReq(result.status)) {
        setData(result.data);
      }
    });
  }, []);

  if (!data) {
    return null;
  }
  const agences = [data.agence_1, data.agence_2, data.agence_3];

  return (
    <article className="page scroll-container" data-router-view="nosAgences" id="page-agence">
      <MetaTags  title={data.metatitle} description={data.metadescription} image=''  name='' />
      <div data-scroll>
        <Ariane name="Nos agences" />
        <section className="pageNosAgences__container">
          <div id="map">
            <picture>
              <source media="(max-width:767px)" srcSet={data.image_carte} />
              <img src={data.image_carte} className="img-responsive" alt="Carte des agences Dymat Loc" />
            </picture>
          </div>
          <div className="agences_container">
            <h1 className="barlowBold to-split intro-reveal"><span>Nos Agences</span></h1>
            <div className="agences">
              {agences.map((agence, index) => (
                <div className="agence-box" key={index} data-id-produit={index}>
                  <div className="box">
                    <h2 className="textUpperCase barlowBold jaune">{agence.titre}</h2>
                    <div className="agence_info barlowMedium black">
                      {agence.address.split("\n").map((line, i) => (
                        <span key={i}>
                          {line}
                          <br />
                        </span>
                      ))}
                      {agence.tel && (
                        <a href={`tel:+33${agence.tel.replace(/\s/g, "")}`} title={`Appelez-nous au ${agence.tel}`} className="to-split intro-reveal">
                          <span style={{ textDecoration: "underline" }} className="barlowBold">
                            Tel : {agence.tel}
                          </span>
                        </a>
                      )}
                      <br />
                      {agence.fax && (
                        <>
                          <span className="barlowBold">Fax : {agence.fax}</span>
                          <br />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </article>
  );
};

export default transition(Agence);
