import gsap, { Power3 } from "gsap";
import { FC, useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Logo from "./main-logo";
import SVG from "./svg";
import { isValidReq } from "../utils/check-status";
import fetchGetSearchProducts from "../utils/request/wp-get-search-product";
import TypeListCategory from "../utils/types/category-list";
import TypeCompleteProduct from "../utils/types/product-complete";
import { VarContext } from "../utils/var-context/devis-context";

const Header: FC<{ listCategorys: TypeListCategory[] }> = ({ listCategorys }) => {
  const context = useContext(VarContext);
  if (!context) return null;

  const { DevisContent } = context;
  const [isMenuOpen, setIsMenuOpen] = useState<false | "search" | "menu">(false);
  const [countProduitDevis, setCountProduitDevis] = useState<number>(DevisContent.length);

  const [results, setResults] = useState<{ needle: string; results: TypeCompleteProduct[] }>({ needle: "", results: [] });

  const navigate = useNavigate();

  const handleSearch = async (searchTerm: string) => {
    fetchGetSearchProducts(searchTerm).then((result) => {
      if (isValidReq(result.status)) {
        setResults({ needle: searchTerm, results: result.data });
      } else console.log(result);
    });
  };

  // Gestion ouverture/fermeture du menu ou de la recherche
  const toggleSearch = () => {
    setIsMenuOpen(isMenuOpen === "search" ? false : "search");
    if (isMenuOpen === "search") setResults({ needle: "", results: [] });
  };
  const toggleMenu = () => setIsMenuOpen(isMenuOpen === "menu" ? false : "menu");

  // Animation GSAP pour ouverture/fermeture du menu
  useEffect(() => {
    if (isMenuOpen === "menu") {
      const tl = gsap.timeline();
      tl.to("#menu-fullPage", { background: "rgba(0, 0, 0, 0.6)", duration: 0.8, ease: Power3.easeOut })
        .to("#menu-notreGamme", { y: 0, duration: 0.6, ease: Power3.easeOut }, "-=0.6")
        .to("#menu-dymatloc", { y: 0, duration: 0.6, ease: Power3.easeOut }, "-=0.6")
        .to(".menu-container .titreMenu span", { y: 0, duration: 0.6, ease: Power3.easeOut }, "-=0.2")
        .to(".menu-container nav ul li a", { y: 0, duration: 0.6, ease: Power3.easeOut }, "-=0.4");
    } else {
      gsap.to("#menu-fullPage", { background: "rgba(0, 0, 0, 0)", duration: 0.6, ease: Power3.easeOut });
    }
  }, [isMenuOpen]);

  //console.log(listCategorys);

  return (
    <header role="banner" className="afterAnim">
      <div className="nav-container">
        <button id="menuButtonOpen" className="menuButton menuButtonOpen" aria-label="Menu" onClick={toggleMenu}>
          <div>
            <svg
              id="ham"
              className={`ham hamRotate ${isMenuOpen === "menu" ? "activeHam" : ""}`}
              viewBox="0 0 100 100"
              width="40%"
            >
              <path className="line top" d="m 70,33 h -40 c 0,0 -6,1.368796 -6,8.5 0,7.131204 6,8.5013 6,8.5013 l 20,-0.0013" />
              <path className="line middle" d="m 70,50 h -40" />
              <path
                className="line bottom"
                d="m 69.575405,67.073826 h -40 c -5.592752,0 -6.873604,-9.348582 1.371031,-9.348582 8.244634,0 19.053564,21.797129 19.053564,12.274756 l 0,-40"
              />
            </svg>
            <p id="menuText" className="hidden-xs barlowBold">
              {!isMenuOpen ? "MENU" : "FERMER"}
            </p>
          </div>
        </button>

        <Link id="devisButton" aria-label="Votre devis" to="/devis" onClick={() => setIsMenuOpen(false)}>
          <div>
            <span id="devisButtonSpanCount" className={countProduitDevis > 0 ? "show white bg_black barlowMedium" : ""}>
              {countProduitDevis > 0 ? countProduitDevis : null}
            </span>
            <SVG select="devis" />
            <p id="menuText" className="hidden-xs barlowMedium fs12-mob">
              Votre devis
            </p>
          </div>
        </Link>

        <Link to="/" onClick={() => setIsMenuOpen(false)}>
          <div id="logo-container">
            <Logo />
          </div>
        </Link>

        <a id="btnRechercherHeader" className={isMenuOpen === "search" ? "open" : ""} onClick={toggleSearch}>
          <div>
            <span className="barlowMedium">Rechercher</span>
            <SVG select={isMenuOpen === "search" ? "close" : "search"} />
          </div>
        </a>

        {isMenuOpen && (
          <div id="menu-fullPage" className={isMenuOpen === "menu" ? "open" : ""}>
            <div id="search-container" className={isMenuOpen === "search" || (isMenuOpen === "menu" && window.innerWidth  < 991 ) ? "open" : window.innerWidth + ""}>
              <button id="closeMenuMobile" onClick={toggleMenu}><SVG select="close"/></button>
              <div className="input-container">
                <form
                  role="search"
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault();
                    toggleSearch;
                    navigate(`/search/${results.needle}`);
                  }}
                >
                  <input
                    type="search"
                    id="header-site-search"
                    className="barlowBold"
                    name="q"
                    aria-label="Que recherchez vous ?"
                    placeholder="Que recherchez vous ?"
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  <div
                    id="produits_trouves_container_header"
                    className={`produits_trouves_container ${results.results.length !== 0 ? "show" : ""}`}
                  >
                    <ul className="liste_produits barlowBold">
                      {results.results.map((product: TypeCompleteProduct) => (
                        <li key={product.id}>
                          <Link to={`/search/${product.name}`} onClick={toggleSearch}>
                            {product.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <button className="flexbox-end-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 13 13">
                      <path
                        className="a"
                        d="M23.841,23.077l-3.125-3.125a5.474,5.474,0,1,0-.767.767l3.125,3.123a.541.541,0,1,0,.767-.764Zm-7.362-2.21a4.389,4.389,0,1,1,4.391-4.388A4.394,4.394,0,0,1,16.479,20.867Z"
                        transform="translate(-11 -11)"
                      ></path>
                    </svg>
                  </button>
                </form>
              </div>
            </div>

            <div id="menu-notreGamme">
              <div className="menu-container">
                <div className="titreMenu barlowBold">
                  <span>Notre gamme</span>
                </div>
                <nav role="navigation" aria-label="Menu principal" id="nav-notreGamme">
                  <ul>
                    {listCategorys?.map((item, i) => {
                      return (
                        <li className="nav-links" key={`${item.id}-${i}`}>
                          <Link to={`/product-category/${item.slug}`} onClick={toggleMenu}>
                            <span className="barlowSemiBold">{item.name}</span>

                            <SVG select={item.svg_icon} />
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              </div>
            </div>

            <div id="menu-dymatloc">
              <div className="menu-container">
                <div className="titreMenu jaune barlowBold">
                  <span>Dymat Loc</span>
                </div>
                <nav role="navigation" aria-label="Menu secondaire" id="nav-Dymatloc">
                  <ul>
                    <li className="nav-links">
                      <Link to="/qui-sommes-nous" onClick={toggleMenu}>
                        <span className="white barlowSemiBold">Qui sommes-nous ?</span>
                        <SVG select="arrow_left" />
                      </Link>
                    </li>
                    <li className="nav-links">
                      <Link to="/contact" onClick={toggleMenu}>
                        <span className="white barlowSemiBold">Contactez-nous</span>
                        <SVG select="arrow_left" />
                      </Link>
                    </li>
                    <li className="nav-links" onClick={toggleMenu}>
                      <Link to="/faq">
                        <span className="white barlowSemiBold">FAQ</span>
                        <SVG select="arrow_left" />
                      </Link>
                    </li>
                    <li className="nav-links" onClick={toggleMenu}>
                      <Link to="/agence">
                        <span className="white barlowSemiBold">Nos agences</span>
                        <SVG select="arrow_left" />
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
