import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import HomePage from "./pages/home-page/home-page";
import PageNotFound from "./pages/not-found/not-found";
import { VarProvider } from "./utils/var-context/devis-context";

import { AnimatePresence } from "framer-motion";
import Lenis from "lenis";
import "./assets/css/animations.scss";
import "./assets/css/doc-le-roux.scss";
import "./assets/css/font.scss";
import "./assets/css/normalize.scss";
import "./assets/css/style-mob.scss";
import "./assets/css/style-tab.scss";
import "./assets/css/style.scss";
import Footer from "./components/footer";
import Header from "./components/header";
import Agence from "./pages/agence/agence";
import SingleCategory from "./pages/category/category";
import Contact from "./pages/contact/contact";
import PageDevis from "./pages/devis/devis";
import FAQ from "./pages/faq/faq";
import MentionsLegales from "./pages/mentions-legales/mentions-legales";
import PlanSite from "./pages/plan-site/plan-site";
import QSN from "./pages/qui-sommes-nous/qui-sommes-nous";
import SearchResultPage from "./pages/search-result/search-result";
import NosConseils from "./pages/template-conseil";
import { animationScrollPage } from "./utils/animations";
import { isValidReq } from "./utils/check-status";
import fetchGetListCategory from "./utils/request/wp-get-list-product-category";
import TypeListCategory from "./utils/types/category-list";
import { FirstLoadProvider } from "./utils/var-context/load-context";
import { HelmetProvider } from 'react-helmet-async';

const App: React.FunctionComponent = () => {
  const [categorys, setCategorys] = useState<TypeListCategory[]>([]);

  const lenis = new Lenis();

  const raf = (time: number) => {
    lenis.raf(time);
    requestAnimationFrame(raf);
  };

  requestAnimationFrame(raf);
  useEffect(() => {
    fetchGetListCategory().then((result) => {
      if (isValidReq(result.status)) {
        setCategorys(result.data);
      }
    });
  }, []);

  let CookieDiv: HTMLDivElement | null = document.querySelector("div#tarteaucitronAlertBig");

  if (CookieDiv) {
    CookieDiv.innerHTML = `
      <span id="tarteaucitronDisclaimerAlert" role="paragraph">
        <strong>Ils sont tous beaux tout chauds. Qui veux goûter nos bons cookies&nbsp;?</strong><br /><br />
        <span style="font-size: 12px">
          En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de cookies pour vous proposer des publicités
          adaptées à vos centres d’intérêts et réaliser des statistiques de l’usage du site.
        </span><br />
      </span>
      <button type="button" class="tarteaucitronCTAButton tarteaucitronAllow" id="tarteaucitronPersonalize2">
        <span class="tarteaucitronCheck" aria-hidden="true"></span> J'accepte
      </button>
      <button type="button" class="tarteaucitronCTAButton tarteaucitronDeny" id="tarteaucitronAllDenied2">
        <span class="tarteaucitronCross" aria-hidden="true"></span> Je refuse
      </button>
      <button
        type="button"
        id="tarteaucitronCloseAlert"
        aria-label="En savoir plus (fenêtre modale)"
        title="En savoir plus (fenêtre modale)"
      >
        En savoir plus
      </button>
  `;
  }
  
  return (
    <HelmetProvider>
      <VarProvider>
        <FirstLoadProvider>
          <Router>
            <Header listCategorys={categorys} />
            <main>
              <AnimatePresence mode="wait">
                <Routes>
                  <Route path="/" element={<HomePage listCategorys={categorys} />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/agence" element={<Agence />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/qui-sommes-nous" element={<QSN />} />
                  <Route path="/mentions-legales" element={<MentionsLegales />} />
                  <Route path="/devis" element={<PageDevis listCategorys={categorys} />} />
                  <Route path="/plan-site" element={<PlanSite listCategorys={categorys} />} />
                  <Route path="/search/:needle" element={<SearchResultPage listCategorys={categorys} />} />
                  <Route path="/product-category/:category_ID" element={<SingleCategory listCategorys={categorys} />} />
                  <Route
                    path="/nos-conseils-comment-choisir-une-pilonneuse"
                    element={<NosConseils title="Location de pilonneuse" wpPageId={93} />}
                  />
                  <Route
                    path="/nos-conseils-comment-choisir-une-plaque-vibrante"
                    element={<NosConseils title="Location de plaques vibrantes" wpPageId={95} />}
                  />
                  <Route
                    path="/nos-conseils-materiels-de-compactage-le-guide-de-la-location"
                    element={
                      <NosConseils
                        title="La location de matériel de compactage : pilonneuses, plaque vibrante, rouleaux compresseurs"
                        wpPageId={91}
                      />
                    }
                  />
                  
                  <Route path="*" element={<PageNotFound/>} />
                </Routes>
              </AnimatePresence>
            </main>
            <Footer />
          </Router>
        </FirstLoadProvider>
      </VarProvider>
    </HelmetProvider>
  );
};

export default App;
