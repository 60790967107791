import TypeDataContact from "../types/data-form-contact";

const fetchPostFormContact = async (formData: TypeDataContact) => {
  var data: any = ''
  try {
      const response = await fetch(`/wp-json/v1/send-contact`, {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),

      });
      data =   {data : await response.json(), status: response.status};
  } catch(error) {
      return error
  }
  return data
}
export default fetchPostFormContact;