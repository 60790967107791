import { FC, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Ariane from "../../components/ariane";
import transition from "../../components/transition";
import { isValidReq } from "../../utils/check-status";
import fetchGetSearchProducts from "../../utils/request/wp-get-search-product";
import TypeListCategory from "../../utils/types/category-list";
import TypeCompleteProduct from "../../utils/types/product-complete";
import { VarContext } from "../../utils/var-context/devis-context";
import "./search-result.css";
import SVG from "../../components/svg";
import Card from "../../components/card";
import MetaTags from "../../components/metatags";


const SearchResultPage: FC<{ listCategorys: TypeListCategory[] }> = ({ listCategorys }) => {
  const { needle } = useParams<{ needle: string }>();
  const [results, setResults] = useState<TypeCompleteProduct[]>([]);

  const context = useContext(VarContext);
  if (!context) return null;
  const { DevisContent, setDevisContent } = context;

  const navigate = useNavigate();

  if (!needle) return null;

  useEffect(() => {
    fetchGetSearchProducts(needle).then((result) => {
      if (isValidReq(result.status)) {
        setResults(result.data);
      } else {
        console.log(result);
      }
    });
  }, [needle]);

  const addProductOnDevis = (itemToAdd: TypeCompleteProduct) => {
    setDevisContent([...DevisContent, itemToAdd]);
    navigate("/devis");
  };

  return (
    <article id="page-search" data-router-view="custom" className="page categorie scroll-container">
      <MetaTags  title="Dymat Loc - Résultats Recherche" description="Résultats Recherche" image=''  name='' />
      <div data-scroll>
        <Ariane name="Recheche" />
        <section id="notre-gamme_container">
          <div className="text-intro">
            <h1 className="visually-hidden">Résultats de recherche</h1>
            <p className="barlowMedium">
              Votre recherche pour <span className="barlowBold">« {needle} »</span> <br />
            </p>
          </div>

          {results.length > 0 ? (
            <div className="produis-container">
              {results.map((item, index) => (
                <Card product={item} fn={addProductOnDevis} key={index}/>
              ))}
            </div>
          ) : (
            <div className="noResultats-container">
              <h2 className="barlowMedium grey">Aucun résultat trouvé pour « {needle} ».</h2>
              <nav id="noResultats_nav-notreGamme">
                <ul>
                  {listCategorys && listCategorys.length > 0
                    ? listCategorys.map((item, index) => {
                        return (
                          <li className="nav-links" key={`${item.name}-${item.id}-${index}`}>
                            <Link to={`/product-category/${item.id}`}>
                              <span className="barlowSemiBold">{item.name}</span>

                              <SVG select={item.svg_icon} />
                            </Link>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </nav>
            </div>
          )}
        </section>
      </div>
    </article>
  );
};

export default transition(SearchResultPage);
