import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import transition from "../../components/transition";
import { animationScrollPage, splitLines } from "../../utils/animations";
import { isValidReq } from "../../utils/check-status";
import fetchGetInfoCategory from "../../utils/request/wp-get-info-category";
import TypeCompleteCategory from "../../utils/types/category-complete";
import TypeListCategory from "../../utils/types/category-list";
import TypeCompleteProduct from "../../utils/types/product-complete";
import { VarContext } from "../../utils/var-context/devis-context";
import "./category.css";
import Ariane from "../../components/ariane";
import SVG from "../../components/svg";
import Card from "../../components/card";
import MetaTags from "../../components/metatags";

type Props = {
  listCategorys: TypeListCategory[];
};

const SingleCategory: React.FC<Props> = () => {
  const { category_ID } = useParams<{ category_ID: string }>();
  const context = useContext(VarContext);
  if (!context) return null;
  const { DevisContent, setDevisContent } = context;

  const navigate = useNavigate();

  const [category, setCategory] = useState<TypeCompleteCategory>();

  useLayoutEffect(() => {
    if (category) {
      Array.prototype.forEach.call(document.querySelectorAll(".to-split span"), function (e, index) {
        splitLines(e, '<span class="line-container"><span>', "</span></span>");
      });
      animationScrollPage();
    }
  }, [category]);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });

    if (category_ID) {
      fetchGetInfoCategory(category_ID).then((result) => {
        if (isValidReq(result.status)) setCategory(result.data);
        else navigate("/404");
      });
    }
  }, [category_ID]);

  const addProductOnDevis = (itemToAdd: TypeCompleteProduct) => {
    setDevisContent([...DevisContent, itemToAdd]);
    navigate("/devis");
  };

  return category ? (
    <article data-router-view="categorie" className="page categorie scroll-container" id="page-category">
      <MetaTags  title={category.metatitle} description={category.metadescription} image=''  name='' />
      <div data-scroll>
        <Ariane name={category.name} />
        <section className="bandeau-container">
          <picture>
            <source srcSet={category.banner_image} media="(min-width:992px)" />
            <source srcSet={category.banner_image} media="(max-width:991px)" />
            <img id="image_bg" src={category.banner_image} alt="" role="presentation" className="img-responsive lazy" />
          </picture>
          <div className="text-container">
            <h1
              className="white barlowBold to-split intro-reveal"
              aria-label={category.name}
              style={category.name === "Manutention tout-terrain" ? { width: "60%" } : {}}
            >
              <span>{category.name}</span>
            </h1>
            <h2 className="white barlowMedium to-split intro-reveal" aria-label={category.banner_description}>
              <span>{category.banner_description}</span>
            </h2>
          </div>
          <div id="scroller">
            <div className="arrow arrow-first"></div>
            <div className="arrow arrow-second"></div>
          </div>
        </section>
        <section id="notre-gamme_container">
          <h2 className="barlowMedium to-split intro-reveal dark_grey" aria-label={category.banner_description}>
            {category.banner_description}
          </h2>
          <div className="text-intro"></div>
          <div className="produis-container">
            {category.products.map((item, index) => (
              <Card product={item} fn={addProductOnDevis} key={index} />
            ))}
          </div>
        </section>
      </div>
    </article>
  ) : null;
};

export default transition(SingleCategory);
