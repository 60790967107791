import { FC, useEffect, useState } from "react";
import transition from "../../components/transition";
import { isValidReq } from "../../utils/check-status";
import fetchGetPageContent from "../../utils/request/wp-get-page-content";
import MetaTags from "../../components/metatags";
import "./mentions-legales.css";
import { animationScrollPage } from "../../utils/animations";
import Ariane from "../../components/ariane";

interface MLdata {
  html: string,
  metatitle: string;
  metadescription: string;
}

const MentionsLegales: FC = () => {
  const [data, setData] = useState<MLdata>();
  useEffect(() => {
    animationScrollPage();
  }, [data]);

  const wpPageID: number = 85;
  useEffect(() => {
    fetchGetPageContent(wpPageID).then((result) => {
      if (isValidReq(result.status)) {
        setData(result.data);
      }
    });
  }, []);

  return data ? (
    <article className="page scroll-container" data-router-view="custom" id="page-mention-legales">
      <MetaTags  title={data.metatitle} description={data.metadescription} image=''  name='' />
      <div data-scroll>
        <Ariane name="Mentions Légales" />
        <section className="page__container">
          <div className="text-intro" dangerouslySetInnerHTML={{ __html: data.html }} />
        </section>
      </div>
    </article>
  ) : null;
};

export default transition(MentionsLegales);
