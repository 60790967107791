import { createContext, useContext, useState, FC, ReactNode } from "react";

interface FirstLoadContextProps {
  isFirstLoad: boolean;
  setFirstLoad: (value: boolean) => void;
}

const FirstLoadContext = createContext<FirstLoadContextProps | undefined>(undefined);

export const FirstLoadProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isFirstLoad, setFirstLoad] = useState(true);

  return <FirstLoadContext.Provider value={{ isFirstLoad, setFirstLoad }}>{children}</FirstLoadContext.Provider>;
};

export const useFirstLoad = (): FirstLoadContextProps => {
  const context = useContext(FirstLoadContext);
  if (!context) {
    throw new Error("useFirstLoad must be used within a FirstLoadProvider");
  }
  return context;
};
